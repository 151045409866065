<template>
  <div class="multiselect-container">
    <label v-if="label">{{ label }}</label>
    <multiselect
      v-model="selectedOptions"
      :options="options"
      :close-on-select="true"
      track-by="value"
      label="label"
      :placeholder="placeholder"
      :searchable="searchable"
      :show-labels="true"
      :clear-on-select="true"
      :custom-label="customLabel"
      class="custom-multiselect-dropdown"
      :taggable="true"
      select-label=""
      deselect-label=""
      selected-label=""
      :multiple="isMultiple"
      @input="emitSelectedOptions"
    >
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.label }}</span>
          <span v-if="isMultiple" class="option__icon"></span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  components: { Multiselect },
  props: {
    options: {
      type: Array,
      required: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Seçiniz',
    },
    modelValue: {
      type: [Array, Object],
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const selectedOptions = ref([]);

    const customLabel = option => {
      return `${option.label}`;
    };

    const emitSelectedOptions = value => {
      const emitValue = props.isMultiple ? value : value ? [value] : [];
      selectedOptions.value = emitValue;
      emit('update:modelValue', emitValue);
      emit('selectedOptions', emitValue);
    };

    watch(
      () => props.modelValue,
      newVal => {
        selectedOptions.value = Array.isArray(newVal) ? newVal : newVal ? [newVal] : [];
      },
      { immediate: true },
    );

    return { selectedOptions, customLabel, emitSelectedOptions };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.multiselect-container {
  width: 240px;

  label {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #79838e;
  }
}

/deep/ .multiselect {
  margin-top: 10px;
  margin-bottom: 20px;
  .multiselect__content-wrapper {
    max-height: 400px !important;
    border-radius: 4px;
    border: 1px solid #79838e;
    margin: 10px 0;
  }
  .multiselect__tags {
    height: 35px;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-left: 20px;
    border: 1px solid #79838e;
    border-radius: 4px;
  }
  .multiselect__tag {
    background: none;
    margin-bottom: 0;
    font-size: 18px;
    color: #23303d;
  }
  .multiselect__placeholder {
    font-size: 14px;
    color: #23303d;
    font-weight: 300;
    margin-bottom: 0;
  }
  .multiselect__option {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 0.5px solid rgba(180, 194, 211, 0.6);
    padding-left: 20px;
    padding-right: 20px;
  }
  .multiselect__option--highlight {
    background: #f8f9f9;
    color: #23303d;
  }
  .multiselect__option--selected {
    background: #f8f9f9;
    font-weight: 300;
    &.multiselect__option--highlight {
      color: #23303d;
      font-weight: 300;
    }
    .option__desc {
      .option__icon {
        width: 16px;
        height: 16px;
        background-color: transparent;
        border: 2px solid #6dd400;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: #6dd400;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
      }

      &.multiselect__option--selected .option__icon {
        border: 2px solid #6dd400;

        &:after {
          background-color: #fff;
        }
      }
    }
  }
  .option__desc {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .option__icon {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #23303d;
    }
  }
  &--active {
    .multiselect__select {
      transform-origin: center;
    }
  }
  .multiselect__select {
    height: 40px;
    &:before {
      content: '';
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #000 transparent transparent transparent;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .multiselect__single,
  .multiselect__tag {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 14px;
    color: #23303d;
  }
}
</style>
